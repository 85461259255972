// src/pages/OrderManagement.js
import React, { useEffect, useState } from 'react';
import apiClient from '../api/axios';
import styles from './OrderManagement.module.css';

const OrderManagement = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await apiClient.get('/orders'); // 모든 주문을 가져오는 API 엔드포인트로 수정 필요
        setOrders(response.data);
      } catch (error) {
        console.error('주문 목록을 가져오는 중 오류 발생:', error);
      }
    };

    fetchOrders();
  }, []);

  const updateOrderStatus = async (orderId, status) => {
    try {
      await apiClient.put(`/order/${orderId}`, { status }); // 주문 상태 업데이트 API 호출
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId ? { ...order, status } : order
        )
      );
    } catch (error) {
      console.error('주문 상태 업데이트 중 오류 발생:', error);
    }
  };

  return (
    <div className={styles.orderManagementContainer}>
      <h1>주문 관리</h1>
      <table className={styles.orderTable}>
        <thead>
          <tr>
            <th>주문 번호</th>
            <th>사용자</th>
            <th>상품 목록</th>
            <th>총 가격</th>
            <th>상태</th>
            <th>상태 변경</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td>{order.id}</td>
              <td>{order.userId}</td>
              <td>
                {order.items.map((item) => (
                  <div key={item.productId}>{item.name} (x{item.quantity})</div>
                ))}
              </td>
              <td>{order.totalPrice}</td>
              <td>{order.status}</td>
              <td>
                <select
                  value={order.status}
                  onChange={(e) => updateOrderStatus(order.id, e.target.value)}
                >
                  <option value="pending">Pending</option>
                  <option value="shipped">Shipped</option>
                  <option value="delivered">Delivered</option>
                  <option value="cancelled">Cancelled</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderManagement;
