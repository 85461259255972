// src/pages/ProductManagement.js
import React, { useEffect, useState } from 'react';
import apiClient from '../api/axios';
import styles from './ProductManagement.module.css';

const ProductManagement = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await apiClient.get('/products');
        setProducts(response.data);
      } catch (error) {
        console.error('상품 목록을 가져오는 중 오류 발생:', error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className={styles.productManagementContainer}>
      <h1>상품 관리</h1>
      <table className={styles.productTable}>
        <thead>
          <tr>
            <th>상품명</th>
            <th>브랜드</th>
            <th>가격</th>
            <th>카테고리</th>
            <th>MD 추천</th>
            <th>수정/삭제</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.id}>
              <td>{product.name}</td>
              <td>{product.brand}</td>
              <td>{product.basePrice}</td>
              <td>{product.category}</td>
              <td>{product.isMdPick ? '예' : '아니오'}</td>
              <td>
                <button>수정</button>
                <button>삭제</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductManagement;
