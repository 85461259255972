// src/pages/Dashboard.js
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Dashboard.module.css';

const Dashboard = () => {
  return (
    <div className={styles.dashboardContainer}>
      <h1>관리자 대시보드</h1>
      <div className={styles.linkContainer}>
        <Link to="/products" className={styles.dashboardLink}>상품 관리</Link>
        <Link to="/orders" className={styles.dashboardLink}>주문 관리</Link>
        {/* 필요한 다른 링크 추가 */}
      </div>
    </div>
  );
};

export default Dashboard;
