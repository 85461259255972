// src/pages/LoginPage.js
import React, { useState } from 'react';
import apiClient from '../api/axios';
import { useNavigate } from 'react-router-dom';
import styles from './LoginPage.module.css';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await apiClient.post('/auth/login', { username, password });

      // 서버에서 받은 토큰을 로컬 스토리지에 저장
      const token = response.data.token;
      localStorage.setItem('token', token);

      // 로그인 성공 후 대시보드로 이동
      navigate('/dashboard');
    } catch (error) {
      setError('로그인 실패. 아이디 또는 비밀번호를 확인하세요.');
    }
  };

  return (
    <div className={styles.loginContainer}>
      <form onSubmit={handleLogin} className={styles.loginForm}>
        <h2>관리자 로그인</h2>
        {error && <p className={styles.errorMessage}>{error}</p>}
        <input
          type="text"
          placeholder="아이디"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          className={styles.inputField}
        />
        <input
          type="password"
          placeholder="비밀번호"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className={styles.inputField}
        />
        <button type="submit" className={styles.loginButton}>로그인</button>
      </form>
    </div>
  );
};

export default LoginPage;
